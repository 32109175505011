import { Space, Button } from "antd";
import FileUpload from "./FileUpload";
import { FileUploadButtonType } from "../../utils/constants";

const FileUploadAndView = ({
  id,
  handleFileUpload,
  handleView,
  // handleDeleteFiles,
  fileName,
  acceptedFiles,
  url,
  uploadText = "File Uploaded"
}) => {
  return (
    <Space wrap>
      <FileUpload
        id={id}
        buttonType={FileUploadButtonType.UPDATE}
        handleFileUpload={handleFileUpload}
        accept={acceptedFiles}
        uploadText={uploadText}
      />
      <Button type="primary" onClick={() => handleView(id, url)}>
        View
      </Button>
      {/* <Button
          danger
          type="primary"
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteFiles(id)}
        /> */}
      <h5 style={{ marginBottom: 0, wordBreak: "normal" }}>{fileName}</h5>
    </Space>
  );
};

export default FileUploadAndView;

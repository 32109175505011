import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { message, notification } from "antd";
import { TEXT_CONSTANTS, NUMBER_CONTANTS, AllowedFileTypes, acceptedFileTypes } from "./constants";

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

export const getFormattedDate = (date) => {
  if (!date) return null;
  try {
    // const formattedDate = dayjs(date1).format("DD-MM-YYYY HH:mm:ss");
    const formattedDate = dayjs(date).format("llll");

    return formattedDate;
  } catch (error) {
    console.log("error", error);
  }
};

export const snakeCaseToUIText = (text) => {
  const txt = text.split("_").join(" ");
  return capitalizeFirstLetter(txt);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const redirectToHomePage = () => {
  window.location = process.env.REACT_APP_BHAAGO_INDIA_HOME_PAGE;
};

export const redirectToOrderDetails = (orderId) => {
  const redirect_url =
    process.env.REACT_APP_BHAAGO_INDIA_TICKET_PLATFORM + "order-details/" + orderId;
  window.open(redirect_url, "_blank");
};

export const trimNumbersToAFixedFigit = (value, fixedFigit = 2) => {
  return +parseFloat(value.toString()).toFixed(fixedFigit);
};

export const getFileSize = (size) => {
  if (size === 0) return "0 Bytes";

  const k = 1024;

  const sizes = ["Bytes", "KB", "MB", "BG", "TB"];

  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const showErrorToast = (msg) => {
  // message.error(msg || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT, NUMBER_CONTANTS.timeForToastError);
  showErrorNotification({ msg: "Error", desc: msg });
};

export const onError = (error) => {
  const msg = getErrorMessages(error);
  message.error(msg, NUMBER_CONTANTS.timeForToastError);
};

export const showErrorNotification = ({ msg, desc }) => {
  notification["error"]({
    message: msg || "Error",
    description: desc || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT,
    duration: 0,
    placement: "topRight"
  });
};

const getErrorMessages = (e) => {
  try {
    if (typeof e?.response?.data === "string") {
      return e.response.data || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT;
    }

    if (typeof e?.response?.data?.message === "string") {
      return e.response.data.message || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT;
    }

    if (typeof e?.response?.data?.error === "string") {
      return e.response.data.error || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT;
    }

    if (typeof e?.message === "string") {
      return e.message || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT;
    }

    if (typeof e?.msg === "string") {
      return e.msg || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT;
    }

    if (Array.isArray(e.response?.data?.message) && e.response?.data?.message?.length > 0) {
      const errorMessageArray = e.response?.data?.message;
      return errorMessageArray
        .map((item) => {
          return item.message;
        })
        .join(" & ");
    }
    const error =
      e?.response?.data?.error || e?.response?.data?.message || TEXT_CONSTANTS.ERROR_FALLBACK_TEXT;

    return error;
  } catch (error) {
    return TEXT_CONSTANTS.ERROR_FALLBACK_TEXT;
  }
};

export const getFileFormat = (fileName) => {
  if (!fileName) return "";

  try {
    const split = fileName.split(".");
    const fileFormat = split[split.length - 1];
    return fileFormat;
  } catch (error) {
    console.log("Error for getFileFormat ", error);
  }
};

export const getFileType = (fileName) => {
  if (!fileName) return "";

  try {
    const split = fileName.split(".");
    const fileFormat = split[split.length - 1];

    if (fileFormat === "pdf") {
      return AllowedFileTypes.PDF;
    }
    if (acceptedFileTypes.IMAGE_TYPES.includes(fileFormat)) {
      return AllowedFileTypes.IMAGE;
    }
    return null;
  } catch (error) {
    console.log("Error for getFileType ", error);
  }
};

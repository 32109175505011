import NoPage from "../../pages/Nopage";
import { orderConfirmationMessage } from "../../utils/constants";
import styles from "./OrderDetailsCard.module.css";
import BibInfoModel from "../BibInfoModel";

import { Button } from "antd";

const OrderDetailsCard = ({ parseOrderData }) => {
  if (!parseOrderData.status) {
    return <NoPage />;
  }
  // parseOrderData?.data?.bib_collection_data

  return (
    <div className={styles.orderConfirmationMainContainer}>
      <h1 className={styles.orderConfiramtionHeader}>{orderConfirmationMessage}</h1>
      {parseOrderData?.data?.bib_collection_data && (
        <>
          {/* <b>Click Here to Know Bib Collection Details:</b> */}
          <Button
            className={styles.bibButtonStyle}
            type="primary"
            onClick={() => {
              BibInfoModel(parseBibCollectionInfoData({ parseOrderData }));
            }}
          >
            Click Here to Know Bib Collection Details
          </Button>
        </>
      )}
      <table className={styles.tableStyle}>
        <tbody>
          <tr>
            <td className={styles.tableItemStyle}>
              <strong>Order ID:</strong>
            </td>
            <td className={styles.tableItemStyle}>
              <strong>{parseOrderData?.data?.order_id}</strong>
            </td>
          </tr>
          {parseOrderData?.data?.user_details?.name ? (
            <tr>
              <td className={styles.tableItemStyle}>Name</td>
              <td className={styles.tableItemStyle}>{parseOrderData?.data?.user_details?.name}</td>
            </tr>
          ) : (
            <></>
          )}
          {parseOrderData?.data?.user_details?.city ? (
            <tr>
              <td className={styles.tableItemStyle}>City</td>
              <td className={styles.tableItemStyle}>{parseOrderData?.data?.user_details?.city}</td>
            </tr>
          ) : (
            <></>
          )}
          {parseOrderData?.data?.user_details?.address ? (
            <tr>
              <td className={styles.tableItemStyle}>User Address</td>
              <td className={styles.tableItemStyle}>
                {parseOrderData?.data?.user_details?.address}
              </td>
            </tr>
          ) : (
            <></>
          )}
          {parseOrderData?.data?.user_details?.tshirt_size ? (
            <tr>
              <td className={styles.tableItemStyle}>T Shirt Size</td>
              <td className={styles.tableItemStyle}>
                {parseOrderData?.data?.user_details?.tshirt_size}
              </td>
            </tr>
          ) : (
            <></>
          )}
          {parseOrderData?.data?.user_details?.date_of_birth ? (
            <tr>
              <td className={styles.tableItemStyle}>Date Of Birth</td>
              <td className={styles.tableItemStyle}>
                {parseOrderData?.data?.user_details?.date_of_birth}
              </td>
            </tr>
          ) : (
            <></>
          )}
          {parseOrderData?.data?.user_details?.blood_group ? (
            <tr>
              <td className={styles.tableItemStyle}>Blood Group</td>
              <td className={styles.tableItemStyle}>
                {parseOrderData?.data?.user_details?.blood_group}
              </td>
            </tr>
          ) : (
            <></>
          )}
          <tr>
            <td className={styles.tableItemStyle}>Email</td>
            <td className={styles.tableItemStyle}>{parseOrderData?.data?.email}</td>
          </tr>
          <tr>
            <td className={styles.tableItemStyle}>Phone</td>
            <td className={styles.tableItemStyle}>{parseOrderData?.data?.phone}</td>
          </tr>
          <tr>
            <td className={styles.tableItemStyle}>Event Name</td>
            <td className={styles.tableItemStyle}>{parseOrderData?.data?.event_name}</td>
          </tr>
          <tr>
            <td className={styles.tableItemStyle}>Event Sub Category</td>
            <td className={styles.tableItemStyle}>
              {parseOrderData?.data?.event_sub_category_name}
            </td>
          </tr>
          <tr>
            <td className={styles.tableItemStyle}>Payment Time</td>
            <td className={styles.tableItemStyle}>{parseOrderData?.data?.payment_time}</td>
          </tr>
          <tr>
            <td className={styles.tableItemStyle}>
              <strong>Amount Paid:</strong>
            </td>
            <td className={styles.tableItemStyle}>
              <strong>₹ {parseOrderData?.data?.amount_paid.toFixed(2)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OrderDetailsCard;

const parseBibCollectionInfoData = ({ parseOrderData }) => {
  if (parseOrderData?.data) {
    const data = parseOrderData.data;
    return {
      eventName: data.event_name,
      eventDate: data.event_date,
      startTime: data.start_time,
      eventCityState: data.event_city_state,
      bibCollectionData: parseOrderData?.data?.bib_collection_data,
      bibCollectionDetails: parseOrderData?.data?.bib_collection_data?.bib_collection_details
    };
  }

  return null;
};

import { useState, useEffect } from "react";
import { Form } from "antd";
import PaymentsCard from "../components/PaymentsCard";
import BIButton from "../components/BIButton";
import BasicLayout from "../components/BasicLayout";
import PaymentsFormSection from "../components/PaymentsFormSection";
import styles from "./payments.module.css";
import { useParams } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import { MOBILE_VIEW, VIEW } from "../utils/constants";
import useAxios from "../hooks/useAxios";
import NotificationComponent from "../components/NotificationComponent";
import BackButton from "../components/BackButton";

const Payments = () => {
  const { eventId } = useParams();
  const [view, setView] = useState(VIEW.MOBILE);
  const size = useWindowSize();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);

  const {
    response: eventData,
    loading,
    error
  } = useAxios({
    method: "get",
    url: `/events/${eventId}`,
    headers: JSON.stringify({ accept: "*/*" })
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (size.width > MOBILE_VIEW.width_threshold) {
      setView(VIEW.DESKTOP);
      return;
    }
    if (size.width <= MOBILE_VIEW.width_threshold) {
      setView(VIEW.MOBILE);
    }
  }, [size.width]);

  const handleNext = () => {
    setPage(2);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleClickBackButton = () => {
    setPage(1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  if (error) {
    return (
      <>
        <NotificationComponent
          type="error"
          message="Error"
          description={error?.message || "Something went wrong"}
        />
      </>
    );
  }
  if (view === VIEW.MOBILE) {
    return (
      <>
        <BasicLayout
          content={
            page === 1 ? (
              <PaymentsCard
                loading={loading}
                parsedEventData={parseEventData({ eventData, eventId })}
              />
            ) : (
              <>
                <BackButton
                  text="Payment Details"
                  handleClickBackButton={handleClickBackButton}
                  className="p3-l p3-r"
                  style={{ backgroundColor: "white" }}
                />
                <PaymentsFormSection
                  loading={loading}
                  view={view}
                  parsedEventData={parseEventData({ eventData, eventId })}
                  form={form}
                />
              </>
            )
          }
        />
        {page === 1 ? (
          <div className={styles.nextButtonContainer}>
            <BIButton text="NEXT" handleClick={handleNext} className={styles.biButton} />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  // FOR DESKTOP VIEW
  return (
    <>
      <BasicLayout
        content={
          <section className="flex max-1080 minHeight100">
            <PaymentsCard
              loading={loading}
              style={{ minWidth: "50%" }}
              parsedEventData={parseEventData({ eventData, eventId })}
            />
            <PaymentsFormSection
              loading={loading}
              style={{ minWidth: "50%" }}
              view={view}
              parsedEventData={parseEventData({ eventData, eventId })}
              form={form}
            />
          </section>
        }
      />
    </>
  );
};

export default Payments;

const parseEventData = ({ eventData, eventId }) => {
  if (eventData) {
    const data = eventData;
    return {
      name: data?.name,
      html_details: data?.details,
      location: data?.location_data,
      event_date: data?.event_date,
      event_sub_category: data?.event_subcategory_data_list,
      eventId: eventId,
      last_registration_date: data?.last_registration_date,
      event_config: data?.event_config,
      event_info: data?.event_info,
      event_formatted_date: data?.event_formatted_date,
      event_start_time: data?.event_start_time,
      event_city_state: data?.event_city_state,
      form_config: data?.form_config
    };
  }

  return null;
};

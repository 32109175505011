import React from "react";

import { Descriptions, Button, Modal } from "antd";
import styles from "./BibCollectionInfo.module.css";

const BibCollectionInfo = ({ parseBiBData }) => {
  const {
    eventName,
    eventDate,
    startTime,
    bibCollectionData,
    bibCollectionDetails,
    eventCityState
  } = parseBiBData || {};

  const bibCollectionTitle = `${eventName} on ${eventDate}`;
  const whatsAppGroupLink = bibCollectionData.whatsapp_group_link;
  const joinWhatsAppGroupOnClick = (whatsAppGroupLinkValue) => {
    // Open link in a new tab
    window.open(whatsAppGroupLinkValue, "_blank");
  };
  // ref: https://ant.design/components/descriptions
  return (
    <>
      <div className={styles.bibHeaderContainer}>
        <h1 className={styles.bibCollectionHeader}>{bibCollectionTitle}</h1>
      </div>

      <Descriptions className={styles.descriptionContainer} title={""} bordered>
        {bibCollectionDetails.map((bibDetails, indexValue) => (
          <>
            <Descriptions.Item
              key={() => `${indexValue}-date`}
              label={`Bib Collection Date-${indexValue + 1}`}
            >
              {bibDetails.date}
            </Descriptions.Item>
            <Descriptions.Item
              key={() => `${indexValue}-venue`}
              label={`Bib Collection Venue-${indexValue + 1}`}
            >
              {bibDetails.venue}
            </Descriptions.Item>
            <Descriptions.Item
              key={() => `${indexValue}-map`}
              label={`Bib Collection Map-${indexValue + 1}`}
            >
              <a href={bibDetails.map_location} target="_blank" rel="noreferrer">
                {bibDetails.map_location}
              </a>
            </Descriptions.Item>
          </>
        ))}
        <Descriptions.Item label="" span={3}></Descriptions.Item>
        <Descriptions.Item label="" span={3}>
          <b>Event Details</b>
        </Descriptions.Item>
        <Descriptions.Item label="Event Date" span={3}>
          {eventDate}
        </Descriptions.Item>
        <Descriptions.Item label="Start Time" span={3}>
          {startTime}
        </Descriptions.Item>
        <Descriptions.Item label="Starting Point" span={3}>
          {bibCollectionData.starting_point}
        </Descriptions.Item>
        <Descriptions.Item label="Location" span={3}>
          {eventCityState}
        </Descriptions.Item>
        <Descriptions.Item label="Google Map" span={3}>
          <a href={bibCollectionData.google_map_link} target="_blank" rel="noreferrer">
            {bibCollectionData.google_map_link}
          </a>
        </Descriptions.Item>
      </Descriptions>
      {whatsAppGroupLink && (
        <Button type="primary" onClick={() => joinWhatsAppGroupOnClick(whatsAppGroupLink)}>
          Join Whatsapp Group
        </Button>
      )}

      <h3>{bibCollectionData.free_text}</h3>
    </>
  );
};

const BibInfoModel = (parseBiBData) => {
  Modal.info({
    width: "100",
    title: "BIB Collection Details",
    content: <BibCollectionInfo parseBiBData={parseBiBData} />,
    onOk() {}
  });
};

export default BibInfoModel;

import { Button, Result } from "antd";
import { useEffect } from "react";
import { redirectToHomePage } from "../utils/helperfunctions";

const NoPage = () => {
  useEffect(() => {
    let timeout = setTimeout(() => {
      redirectToHomePage();
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist. Redirecting you to home page in 5 seconds."
      extra={
        <Button type="primary" onClick={redirectToHomePage}>
          Back Home
        </Button>
      }
    />
  );
};
export default NoPage;

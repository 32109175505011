import { useEffect, useState } from "react";
import { Form, Button, Typography, Divider, InputNumber, message, Spin, Space } from "antd";
import EditableForms from "./Forms/EditableFroms";
import {
  RowShowInputTypes,
  PATTERN_REGEX,
  GENDERS,
  T_SHIRT_SIZES,
  VIEW,
  TEXT_CONSTANTS,
  PAYMENT_BACKEND_STATUS,
  EVENT_CANCELLATION_REASON,
  FileUploadButtonType,
  acceptedFileTypes,
  BLOOD_GROUPS
} from "../utils/constants";
import styles from "./paymentsForm.module.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import usePaymentsFormSection from "../hooks/usePaymentsFormSection";
import CategoryCard from "./CategoryCard";
import PaymentSuccessModal from "./PaymentSuccessModal";
import ToolTipComponent from "./TooltipComponent";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import FileUpload from "./FileUpload/FileUpload";
import FileUploadAndView from "./FileUpload/FileUploadAndView";
import RedStar from "./Common/RedStart";
import ViewUploadFileModal from "./FileUpload/ViewUploadedFileModal";
import { getFileType } from "../utils/helperfunctions";

const { Title } = Typography;
dayjs.extend(customParseFormat);

const PaymentsFormSection = ({ style, view, parsedEventData, form, loading }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const categoryIndexValue = Form.useWatch("category", form);
  const mobileWhatsappCheckboxValue = Form.useWatch("phone_whatsapp_same", form);
  const mobileValue = Form.useWatch("phone", form);
  const couponCodeValue = Form.useWatch("coupon_code", form);

  const location = useLocation();
  const { event_config, form_config } = parsedEventData || {};
  const { payment_enabled, msg_type } = event_config || {};
  const { consent_config, custom_fields, exclude_fields } = form_config || {};

  const {
    handleSubmit,
    event_sub_category,
    paymentApiStatus,
    showPaymentSuccessModal,
    paymentSuccessData,
    closeModal,
    name,
    handleFileUpload,
    uploadLoader,
    uploadedFileData,
    setUploadedFileData,
    viewFile,
    setViewFile,
    handleView,
    handleFreeEventSubmit,
    handleDiscountCouponApply,
    isDiscountLoading,
    discountCouponError,
    discountCouponData,
    clearDiscountCouponStates
  } = usePaymentsFormSection({
    parsedEventData,
    messageApi
  });

  const eventSubCategoryData = parsedEventData?.event_sub_category[categoryIndexValue];

  // if category is changed, clear the discount coupon states
  useEffect(() => {
    clearDiscountCouponStates();
    form.setFieldValue("coupon_code", "");
  }, [categoryIndexValue]);

  useEffect(() => {
    if (mobileWhatsappCheckboxValue) {
      form.setFieldValue("whatsapp_contact", mobileValue);
    }
  }, [mobileValue, mobileWhatsappCheckboxValue]);

  const handlePaymentSuccessModal = () => {
    closeModal();
    form.resetFields();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setUploadedFileData(undefined);
  };

  if (loading) {
    return (
      <div style={style} className={styles.paymentsFormContainer}>
        <Spin spinning={loading} className="flex justify-center" style={{ paddingTop: "1.5rem" }} />
      </div>
    );
  }

  if (payment_enabled !== true) {
    return (
      <div style={style} className={styles.paymentsFormContainer}>
        <Title level={4} className="m0 m2-t p3-l p3-r">
          {getNoPaymentText(msg_type)}
        </Title>
      </div>
    );
  }

  return (
    <div style={style} className={styles.paymentsFormContainer}>
      {showPaymentSuccessModal ? (
        <PaymentSuccessModal
          isVisible={showPaymentSuccessModal}
          orderId={paymentSuccessData?.orderId}
          paymentId={paymentSuccessData?.paymentId}
          email={paymentSuccessData?.email}
          eventName={paymentSuccessData?.eventName}
          eventSubCategoryName={paymentSuccessData?.eventSubCategoryName}
          handlePaymentSuccessModal={handlePaymentSuccessModal}
        />
      ) : (
        <></>
      )}

      {contextHolder}
      {view === VIEW.DESKTOP ? (
        <Title level={4} className="m0 m2-t p3-l p3-r">
          Payment Details
        </Title>
      ) : (
        <></>
      )}
      <Divider className="m0 m2-b" />
      <Form
        className={styles.paymentsForm}
        form={form}
        name="create_payments_form"
        onFinish={(values) =>
          event_sub_category?.[categoryIndexValue]?.extra_info?.event_type === "FREE"
            ? handleFreeEventSubmit(values, consent_config, custom_fields)
            : handleSubmit(values, consent_config, custom_fields)
        }
        layout="vertical"
        initialValues={getInitialValues(location?.pathname, event_sub_category)}
      >
        <EditableForms
          label={
            <div className="fw500" style={{ fontSize: "16px" }}>
              Select Category
            </div>
          }
          id="category"
          type={RowShowInputTypes.RADIO}
          labelToShow={true}
          marginBottom={true}
          arrayData={getArrayDataForCategorySelection(event_sub_category)}
        />
        {typeof categoryIndexValue === "number" ? (
          <>
            <CategoryCard
              eventSubCategoryData={eventSubCategoryData}
              name={name}
              discountCouponData={discountCouponData}
            />

            {event_config?.payment_enabled &&
            event_config?.coupon_enabled &&
            eventSubCategoryData?.extra_info?.event_type !== "FREE" ? (
              <>
                <Space>
                  <EditableForms
                    label={"Discount Coupon"}
                    id={"coupon_code"}
                    type={RowShowInputTypes.TEXT}
                    labelToShow={true}
                    marginBottom={false}
                    required={false}
                  />
                  <Button
                    type="primary"
                    block
                    style={{ marginTop: "1.75rem" }}
                    loading={isDiscountLoading}
                    onClick={() =>
                      handleDiscountCouponApply(
                        couponCodeValue,
                        event_sub_category[categoryIndexValue]?.id
                      )
                    }
                  >
                    Apply
                  </Button>
                </Space>
                <p className="m0 m2-b">
                  <span className="danger">{discountCouponError}</span>
                  <span className="success">{discountCouponData?.description}</span>
                </p>
              </>
            ) : (
              <> </>
            )}

            {!exclude_fields?.includes("name") && (
              <EditableForms
                label={"Full Name"}
                id={"name"}
                type={RowShowInputTypes.TEXT}
                labelToShow={true}
                marginBottom={true}
              />
            )}
            {!exclude_fields?.includes("email") && (
              <EditableForms
                label={
                  <>
                    Email{" "}
                    <ToolTipComponent title={TEXT_CONSTANTS.emailText}>
                      <InfoCircleTwoTone className="m1-l" />
                    </ToolTipComponent>
                  </>
                }
                id={"email"}
                type={RowShowInputTypes.TEXT}
                labelToShow={true}
                marginBottom={true}
                inputType="email"
              />
            )}
            {!exclude_fields?.includes("phone") && (
              <EditableForms
                addonBefore="+91"
                label="Mobile"
                id="phone"
                type={RowShowInputTypes.NUMBER_WITH_CUSTOM_RULE}
                labelToShow={true}
                marginBottom={true}
                pattern={PATTERN_REGEX.mobile}
              />
            )}

            {!exclude_fields?.includes("phone_whatsapp_same") && (
              <EditableForms
                label={"Same as Mobile"}
                id={"phone_whatsapp_same"}
                type={RowShowInputTypes.CHECKBOX}
                labelToShow={true}
                marginBottom={false}
                required={false}
              />
            )}

            {!exclude_fields?.includes("whatsapp_contact") && (
              <EditableForms
                addonBefore="+91"
                label={
                  <>
                    Whatsapp Mobile{" "}
                    <ToolTipComponent title={TEXT_CONSTANTS.whatsappText}>
                      <InfoCircleTwoTone className="m1-l" />
                    </ToolTipComponent>
                  </>
                }
                id="whatsapp_contact"
                type={RowShowInputTypes.NUMBER_WITH_CUSTOM_RULE}
                labelToShow={true}
                marginBottom={true}
                pattern={PATTERN_REGEX.mobile}
                disabled={mobileWhatsappCheckboxValue ? true : false}
              />
            )}

            {!exclude_fields?.includes("date_of_birth") && (
              <EditableForms
                label={"Date Of Birth"}
                id={"date_of_birth"}
                type={RowShowInputTypes.DATE}
                labelToShow={true}
                marginBottom={true}
                disabledDate={disabledDate}
                defaultPickerValue={dayjs("23-10-1995", "DD-MM-YYYY")}
              />
            )}

            {!exclude_fields?.includes("gender") && (
              <EditableForms
                label={"Gender"}
                id={"gender"}
                type={RowShowInputTypes.SINGLE_SELECT}
                labelToShow={true}
                marginBottom={true}
                arrayData={GENDERS}
              />
            )}

            {!exclude_fields?.includes("tshirt_size") && (
              <EditableForms
                label={"T Shirt Size"}
                id={"tshirt_size"}
                type={RowShowInputTypes.SINGLE_SELECT}
                labelToShow={true}
                marginBottom={true}
                arrayData={T_SHIRT_SIZES}
              />
            )}

            {!exclude_fields?.includes("address") && (
              <EditableForms
                label={"Address"}
                id={"address"}
                type={RowShowInputTypes.TEXT_AREA}
                labelToShow={true}
                marginBottom={true}
              />
            )}
            {!exclude_fields?.includes("city") && (
              <EditableForms
                label={"City"}
                id={"city"}
                type={RowShowInputTypes.TEXT}
                labelToShow={true}
                marginBottom={true}
              />
            )}
            {!exclude_fields?.includes("emergency_contact_name") && (
              <EditableForms
                label={"Emergency Person Contact Name"}
                id={"emergency_contact_name"}
                type={RowShowInputTypes.TEXT}
                labelToShow={true}
                marginBottom={true}
              />
            )}

            {!exclude_fields?.includes("emergency_contact_number") && (
              <Form.Item
                label="Emergency Person Contact Number"
                name="emergency_contact_number"
                rules={[
                  {
                    required: true,
                    message: `Please input valid Emergency Person Contact Number!`
                  },
                  {
                    pattern: PATTERN_REGEX.mobile,
                    message: `Please input valid Emergency Person Contact Number!!`
                  },
                  {
                    validator: (rule, typedValue) => {
                      if (mobileValue && typedValue === mobileValue) {
                        return Promise.reject(
                          "Mobile Number and Emergency Person Contact Number cannot be same"
                        );
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <InputNumber style={{ width: "100%" }} disabled={false} addonBefore="+91" />
              </Form.Item>
            )}

            {!exclude_fields?.includes("blood_group") && (
              <EditableForms
                label={"Blood Group"}
                id={"blood_group"}
                type={RowShowInputTypes.SINGLE_SELECT}
                labelToShow={true}
                marginBottom={true}
                arrayData={BLOOD_GROUPS}
              />
            )}

            {!exclude_fields?.includes("instagram_link") && (
              <EditableForms
                label={"Instagram Link"}
                id={"instagram_link"}
                type={RowShowInputTypes.TEXT}
                labelToShow={true}
                marginBottom={true}
                required={false}
                inputType="url"
              />
            )}
            {!exclude_fields?.includes("facebook_link") && (
              <EditableForms
                label={"Facebook Link"}
                id={"facebook_link"}
                type={RowShowInputTypes.TEXT}
                labelToShow={true}
                marginBottom={true}
                required={false}
                inputType="url"
              />
            )}
            {!exclude_fields?.includes("linkedin_link") && (
              <EditableForms
                label={"Linkedin Link"}
                id={"linkedin_link"}
                type={RowShowInputTypes.TEXT}
                labelToShow={true}
                marginBottom={true}
                required={false}
                inputType="url"
              />
            )}

            {custom_fields?.map((field, index) => (
              <EditableForms
                key={index}
                label={field.label}
                id={field.id}
                type={field.type}
                labelToShow={true}
                marginBottom={true}
                required={field.required}
                arrayData={field.arrayData}
              />
            ))}
            {event_sub_category[categoryIndexValue]?.extra_info?.uploadTimingCertificate ? (
              <>
                <p className="m0 p1-b">
                  <RedStar />
                  <span style={{ marginLeft: 4 }}>Upload Past Timing Certificate</span>
                </p>
                <Spin spinning={uploadLoader}>
                  {uploadedFileData ? (
                    <FileUploadAndView
                      id={"pastTimingCertificate"}
                      handleFileUpload={handleFileUpload}
                      handleView={handleView}
                      // handleDeleteFiles={() => console.log("delete")}
                      fileName=""
                      acceptedFiles={acceptedFileTypes.TIMING_CERTIFICATES}
                      url={uploadedFileData}
                      uploadText={"File Uploaded"}
                    />
                  ) : (
                    <FileUpload
                      id={"pastTimingCertificate"}
                      buttonType={FileUploadButtonType.NEW}
                      handleFileUpload={handleFileUpload}
                      accept={acceptedFileTypes.TIMING_CERTIFICATES}
                      uploadText={uploadedFileData ? "File Uploaded" : "Upload"}
                    />
                  )}
                </Spin>
              </>
            ) : (
              <></>
            )}
            {consent_config ? (
              <>
                {consent_config.map((el, index) => (
                  <EditableForms
                    key={el.link + index}
                    label={
                      <p>
                        <a href={el.external_link} target="_blank" rel="noopener noreferrer">
                          {el.text}
                        </a>
                      </p>
                    }
                    id={`consent${index + 1}`}
                    type={RowShowInputTypes.CHECKBOX}
                    labelToShow={true}
                    marginBottom={false}
                    required={true}
                    errorLabel={`Please check ${el.text}`}
                  />
                ))}
                <DefaultConsent />
              </>
            ) : (
              <DefaultConsent />
            )}

            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                block
                className="m2-t"
                loading={paymentApiStatus === PAYMENT_BACKEND_STATUS.LOADING || uploadLoader}
              >
                Pay {TEXT_CONSTANTS.ruppee_symbol}{" "}
                {discountCouponData?.final_price_data?.final_price ||
                  eventSubCategoryData?.final_price_data?.final_price}
              </Button>
            </Form.Item>
          </>
        ) : (
          <></>
        )}
      </Form>
      {viewFile ? (
        <ViewUploadFileModal
          visible={viewFile}
          handleCancel={() => setViewFile(false)}
          fileData={{
            fileName: "",
            url: uploadedFileData,
            fileType: getFileType(uploadedFileData)
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default PaymentsFormSection;

const DefaultConsent = () => {
  return (
    <EditableForms
      label={
        <p>
          I agree to{" "}
          <a href={TEXT_CONSTANTS.BHAAGOINDIA_TNC_LINK} target="_blank" rel="noopener noreferrer">
            BhaagoIndia TnC.
          </a>
        </p>
      }
      id={"default_bhaago_india_consent"}
      type={RowShowInputTypes.CHECKBOX}
      labelToShow={true}
      marginBottom={false}
      required={true}
      errorLabel="Please check I agree to Bhaago India Tnc."
    />
  );
};

const disabledDate = (current) => {
  return current > dayjs().subtract(5, "year");
};

const getArrayDataForCategorySelection = (event_sub_category) => {
  let formattedData = [];
  let itemName;
  if (event_sub_category) {
    for (let i = 0; i < event_sub_category?.length; i++) {
      itemName = event_sub_category[i]?.display_name;
      const itemStatus = event_sub_category[i]?.status;
      let buttonDisabled = false;
      if (
        event_sub_category[i]?.status == "Sold Out" ||
        event_sub_category[i]?.status == "Disabled"
      ) {
        itemName = `${itemName}-${itemStatus}`;
        buttonDisabled = true;
      }
      // Button Disabled Status
      formattedData.push({
        id: i,
        name: itemName,
        buttonDisabled: buttonDisabled
      });
    }
  }
  return formattedData;
};

const getInitialValues = (locationPathname, event_sub_category) => {
  // for staging
  if (process.env.REACT_APP_ROLLBAR_ENV === "test_env" && locationPathname.includes("book-fill")) {
    return {
      category: 0,
      name: "John Doe",
      email: "johndoe@gmail.com",
      phone: 7897897890,
      phone_whatsapp_same: true,
      whatsapp_contact: 7897897890,
      date_of_birth: dayjs("16/10/1995", "DD/MM/YYYY"),
      gender: "Male",
      tshirt_size: "Exta_large",
      address: "Lower Parel, Mumbai",
      city: "Mumbai",
      emergency_contact_name: "Bhau",
      emergency_contact_number: 7897897891,
      facebook_link: "",
      linkedin_link: "",
      blood_group: "O+"
    };
  }

  if (event_sub_category?.length === 1) {
    return {
      category: 0,
      phone_whatsapp_same: true
    };
  }

  return { phone_whatsapp_same: true };
};

export const getNoPaymentText = (msg_type) => {
  if (msg_type === EVENT_CANCELLATION_REASON.REGISTRATION_CLOSED) {
    return "Registration is Closed.";
  }

  if (msg_type === EVENT_CANCELLATION_REASON.EVENT_CANCELLED) {
    return "Event is Cancelled.";
  }

  if (msg_type === EVENT_CANCELLATION_REASON.EVENT_OVER) {
    return "Event is Over.";
  }

  if (msg_type === EVENT_CANCELLATION_REASON.EVENT_POSTPONED) {
    return "Event is Postponed.";
  }

  return "Not taking registrations at the moment!";
};

import { Modal } from "antd";

import { AllowedFileTypes } from "../../utils/constants";

const ViewUploadFileModal = ({ visible, handleCancel, fileData }) => {
  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      title={fileData?.fileName || ""}
      // onOk={handleOk}
      // onFinish={handleFinish}
      onCancel={handleCancel}
      footer={null}
    >
      {fileData.fileType === AllowedFileTypes.IMAGE && (
        <div style={{ padding: "1rem", objectFit: "cover" }}>
          <img src={fileData.url} alt="file" style={{ marginLeft: "auto", marginRight: "auto" }} />
        </div>
      )}

      {fileData.fileType === AllowedFileTypes.PDF && (
        <iframe src={fileData.url} title="PDF File" style={{ minHeight: "70vh", width: "95%" }}>
          Presss me:
          <a href={fileData.url}>Download PDF</a>
        </iframe>
      )}
    </Modal>
  );
};

export default ViewUploadFileModal;

import { useState, useEffect } from "react";
import { useRollbar } from "@rollbar/react";
import { lambdaInstance } from "../api";

const useAxios = ({ url, method, body = null, headers = null }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const rollbar = useRollbar();

  const fetchData = () => {
    if (url) {
      lambdaInstance[method](url, JSON.parse(headers), JSON.parse(body))
        .then((res) => {
          setResponse(res.data);
        })
        .catch((err) => {
          setError(err);
          rollbar.error("Error for api ", error, { url }, { body });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // removing the error message
  useEffect(() => {
    let timeout;
    if (error) {
      timeout = setTimeout(() => {
        setError("");
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [error]);

  useEffect(() => {
    fetchData();
  }, [method, url, body, headers]);

  return { response, error, loading };
};

export default useAxios;

import { Layout, Typography } from "antd";
import styles from "./basicLayout.module.css";
import { biLogoImage } from "../assets/imageLinks";

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

const BasicLayout = ({ content }) => {
  return (
    <Layout>
      <Header className={styles.header}>
        <img
          src={biLogoImage}
          alt="bhaago india logo"
          className={styles.logo}
          width="108"
          height="64"
        />
      </Header>
      <Content className="site-layout">{content}</Content>
      <Footer className={styles.footer}>
        <div>
          <img src={biLogoImage} alt="bhaago india logo" width="108" height="64" />
          <Text className="flex m1-t">
            We envision to be the one-stop platform for all running needs for runners of India.
          </Text>
        </div>
        <div className="flex column">
          <Title level={4} className="m0 m2-t">
            Pages
          </Title>
          {PAGES.map((el) => (
            <PageLink key={el.href} href={el.href} text={el.text} />
          ))}
        </div>
      </Footer>
    </Layout>
  );
};

export default BasicLayout;

const PageLink = ({ href, text }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className="link-color m1-b">
      {text}
    </a>
  );
};

const PAGES = [
  {
    href: "https://bhaagoindia.com/contact/",
    text: "Contact Us"
  },
  {
    href: "https://bhaagoindia.com/terms-and-conditions/",
    text: "Terms & Conditions"
  },
  {
    href: "https://bhaagoindia.com/cancellation-policy/",
    text: "Cancellation Policy"
  },
  {
    href: "https://bhaagoindia.com/privacy/",
    text: "Privacy Policy"
  },
  {
    href: "https://bhaagoindia.com/faq/",
    text: "FAQ"
  }
];

import axios from "axios";

const LambdaURL = process.env.REACT_APP_LAMBDA_BASE_URL;
const FileUploadWorkerUrl = process.env.REACT_APP_FILE_UPLOAD_WORKER_URL;

// For common configs
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "true";

export const lambdaInstance = axios.create({
  baseURL: LambdaURL,
  withCredentials: false
});

export const fileUploadInstance = axios.create({
  baseURL: FileUploadWorkerUrl,
  withCredentials: false
});

// Api response check for lambdaInstance Instance
// lambdaInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error?.response) {
//       // For unauthorized ie if 401, then redirecting to logout page of Old Admin Panel
//       const { status, statusText } = error.response;
//       if (status === 401 || statusText === "Unauthorized") {
//         logout();
//       }
//       // For forbidden ie if 403, then redirecting to logout page of Old Admin Panel
//       if (status === 403 || statusText === "Forbidden") {
//         logout();
//       }
//     }

//     return Promise.reject(error);
//   }
// );

// // Api response check for fileUploadInstance Instance
// fileUploadInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error?.response) {
//       // For unauthorized ie if 401, then redirecting to logout page of Old Admin Panel
//       const { status, statusText } = error.response;
//       if (status === 401 || statusText === "Unauthorized") {
//         logout();
//       }
//       // For forbidden ie if 403, then redirecting to logout page of Old Admin Panel
//       if (status === 403 || statusText === "Forbidden") {
//         logout();
//       }
//     }

//     return Promise.reject(error);
//   }
// );

// Api request check for lambdaInstance Instance
// lambdaInstance.interceptors.request.use(
//   async function (config) {
//     const isJWTChanged = checkJWTTokenChanged();
//     if (isJWTChanged) {
//       // console.log("JWT_CHANGED......");
//       await changeNodeTokenFromBackend();
//     }
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// // Api request check for fileUploadInstance Instance
// fileUploadInstance.interceptors.request.use(
//   async function (config) {
//     const isJWTChanged = checkJWTTokenChanged();
//     if (isJWTChanged) {
//       // console.log("JWT_CHANGED......");
//       await changeNodeTokenFromBackend();
//     }
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

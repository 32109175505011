import { notification } from "antd";

// const NotificationTypes = ["success", "info", "warning", "error"];

const NotificationComponent = ({ type, message, description }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: message,
      description: description
    });
  };

  if (type && message && description) {
    openNotificationWithIcon(type);
  }

  return <>{contextHolder}</>;
};

export default NotificationComponent;

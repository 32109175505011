export const RowShowInputTypes = {
  TEXT: "TEXT",
  TEXT_AREA: "TEXT_AREA",
  NUMBER: "NUMBER",
  MULTI_SELECT: "MULTI_SELECT",
  TAG: "TAG",
  SINGLE_SELECT: "SINGLE_SELECT",
  DATE: "DATE",
  DEBOUNCE_SELECT: "DEBOUNCE_SELECT",
  PINCODE: "PINCODE",
  NUMBER_WITH_CUSTOM_RULE: "NUMBER_WITH_CUSTOM_RULE",
  STRING_WITH_CUSTOM_RULE: "STRING_WITH_CUSTOM_RULE",
  PASSWORD: "PASSWORD",
  EMAIL: "EMAIL",
  CHECKBOX: "CHECKBOX",
  RADIO: "RADIO",
  RADIO_BUTTON: "RADIO_BUTTON"
};

export const PATTERN_REGEX = {
  gstin: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  aadhar: /^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/,
  pan: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  latitude: /^[-]?(([0-8]?[0-9])\.(\d+))|(90(\.0+)?)$/,
  longitude: /^[-]?((((1[0-7][0-9])|([0-9]?[0-9]))\.(\d+))|180(\.0+)?)$/,
  mobile: /^[0-9]{10}$/,
  email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  password: /^[a-zA-Z0-9!@#$%^&*]{6}$/,
  alphanumeric: /^[a-z0-9]+$/i,
  wholePositive: /^(0|[1-9]\d*)$/,
  postiveNumber: /^[+]?([0-9]+(?:[\\.][0-9]*)?|\.[0-9]+)$/
};

export const GENDERS = [
  {
    id: "Male",
    name: "Male"
  },
  {
    id: "Female",
    name: "Female"
  },
  {
    id: "Non_Binary",
    name: "Non Binary"
  }
];

export const T_SHIRT_SIZES = [
  {
    id: "Extra_Small",
    name: "XS"
  },
  {
    id: "Small",
    name: "S"
  },
  {
    id: "Medium",
    name: "M"
  },
  {
    id: "Large",
    name: "L"
  },
  {
    id: "Extra_Large",
    name: "XL"
  },
  {
    id: "Extra_Extra_Large",
    name: "XXL"
  }
];

export const BLOOD_GROUPS = [
  {
    id: "A+",
    name: "A+"
  },
  {
    id: "A-",
    name: "A-"
  },
  {
    id: "B+",
    name: "B+"
  },
  {
    id: "B-",
    name: "B-"
  },
  {
    id: "O+",
    name: "O+"
  },
  {
    id: "O-",
    name: "O-"
  },
  {
    id: "AB+",
    name: "AB+"
  },
  {
    id: "AB-",
    name: "AB-"
  }
];

export const MOBILE_VIEW = {
  width_threshold: 767
};

export const VIEW = {
  MOBILE: "MOBILE",
  DESKTOP: "DESKTOP"
};

export const TEXT_CONSTANTS = {
  ruppee_symbol: "₹",
  emailText: "We will send payment and event relate details to you",
  whatsappText: "We will send events related details to you",
  nriText: "If you are NRI, contact Bhaago India before booking.",
  helpText: {
    initial: "For any help, contact us at",
    email: "hello@bhaagoindia.com"
  },
  ERROR_FALLBACK_TEXT: "Something went wrong, Please try again later!",
  BHAAGOINDIA_TNC_LINK: "https://bhaagoindia.com/terms-and-conditions/"
};

export const PAYMENT_BACKEND_STATUS = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS"
};

export const EVENT_CANCELLATION_REASON = {
  REGISTRATION_CLOSED: "REGISTRATION_CLOSED",
  EVENT_OVER: "EVENT_OVER",
  EVENT_CANCELLED: "EVENT_CANCELLED",
  EVENT_POSTPONED: "EVENT_POSTPONED"
};

export const FileUploadButtonType = {
  NEW: "NEW",
  UPDATE: "UPDATE"
};

export const NUMBER_CONTANTS = {
  timeForToastError: 7,
  maxSizeForUpload: 2 * 1024 * 1024,
  twoMb: 2 * 1024 * 1024,
  tenMb: 10 * 1024 * 1024,
  threeMb: 3 * 1024 * 1024
};

export const FileComingFrom = {
  FRONTEND: "FRONTEND",
  BACKEND: "BACKEND"
};

export const AllowedFileTypes = {
  IMAGE: "IMAGE",
  PDF: "PDF",
  CSV: "CSV",
  UNKNOWN: "UNKNOWN"
};

export const acceptedFileTypes = {
  TIMING_CERTIFICATES: "image/jpg,image/png,image/gif,image/jpeg,image/webp,application/pdf",
  IMAGE_TYPES: "image/jpg,image/png,image/gif,image/jpeg,image/webp"
};

export const orderConfirmationMessage = "Your Order is confirmed";

export const imageUrls = {
  logoImageUrl: "https://img.bhagoindia.com/common/logo/mini_circle_logo.png",
  facebookUrl: "https://img.bhagoindia.com/common/social/facebook.png",
  instagramUrl: "https://img.bhagoindia.com/common/social/instagram.png",
  stravaUrl: "https://img.bhagoindia.com/common/social/strava.png",
  whatsappUrl: "https://img.bhagoindia.com/common/social/whatsapp.png",
  youtubeUrl: "https://img.bhagoindia.com/common/social/youtube.png"
};

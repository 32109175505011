import { useEffect } from "react";
import { PAYMENT_BACKEND_STATUS, TEXT_CONSTANTS } from "../utils/constants";
import { showErrorNotification } from "../utils/helperfunctions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useState, useRef } from "react";
import { useRollbar } from "@rollbar/react";
import { lambdaInstance } from "../api";
dayjs.extend(customParseFormat);

const usePaymentsFormSection = ({ parsedEventData, messageApi }) => {
  const { event_sub_category, eventId, name } = parsedEventData || {};
  const rollbar = useRollbar();

  const [paymentApiStatus, setPaymentApiStatus] = useState(PAYMENT_BACKEND_STATUS.IDLE);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
  const paymentSuccessDataRef = useRef();

  const [uploadedFileData, setUploadedFileData] = useState(undefined);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [viewFile, setViewFile] = useState(false);
  const [paymentsGoingOn, setPaymentsGoingOn] = useState(false);

  // Discount Coupon
  const [isDiscountLoading, setIsDiscountLoading] = useState(false);
  const [discountCouponData, setDiscountCouponData] = useState("");
  const [discountCouponError, setDiscountCouponError] = useState("");

  const closeModal = () => {
    setShowPaymentSuccessModal(false);
    paymentSuccessDataRef.current = null;
  };

  useEffect(() => {
    const handleTabClose = (event) => {
      // if (paymentsGoingOn) {
      // console.log({ paymentsGoingOn });
      event.preventDefault();

      // console.log("beforeunload event triggered");

      return (event.returnValue = paymentsGoingOn
        ? "Are you sure you want to exit? Payment is in progress"
        : "");
      // }
    };

    window.addEventListener("beforeunload", handleTabClose);
    window.addEventListener("unload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
      window.removeEventListener("unload", handleTabClose);
    };
  }, [paymentsGoingOn]);

  const handleFreeEventSubmit = async (values, consent_config, custom_fields) => {
    // checking whether user has uploaded the file or not, if file upload is required.
    const eventSubCategorySelected = values.category;
    if (
      event_sub_category[eventSubCategorySelected]?.extra_info?.uploadTimingCertificate === true
    ) {
      if (!uploadedFileData) {
        showErrorNotification({ desc: "Please upload past timing Certificate to pay" });
        return;
      }
    }

    if (!values?.default_bhaago_india_consent) {
      showErrorNotification({ desc: "Please Check I agree to Bhaggo India Tnc." });
      return;
    }

    const formattedDateOfBirth = dayjs(values.date_of_birth).format("DD-MM-YYYY");

    const event_sub_category_id = event_sub_category[values.category]?.id;

    const consent = {
      default_config: `$text$: I agree to Bhaago India Tnc. $link$: ${TEXT_CONSTANTS.BHAAGOINDIA_TNC_LINK}`
    };

    if (consent_config) {
      for (let i = 0; i < consent_config?.length; i++) {
        if (!values?.[`consent${i + 1}`]) {
          showErrorNotification({ desc: `Please Check ${consent_config[i].text}` });
          return;
        }
        consent[
          `consent ${i + 1}`
        ] = `$text$: ${consent_config[i]?.text}, $link$: ${consent_config[i]?.external_link}`;
      }
    }

    const payload = {
      email: values?.email,
      phone: values?.phone?.toString(),
      name: values?.name,
      tshirt_size: values?.tshirt_size,
      gender: values?.gender,
      address: values?.address,
      //   discount_code: "ABCD",
      emergency_contact_number: values?.emergency_contact_number?.toString(),
      emergency_contact_name: values?.emergency_contact_name,
      extra: {
        whatsapp_contact: values?.whatsapp_contact.toString(),
        city: values?.city,
        date_of_birth: formattedDateOfBirth.toString(),
        instagram_link: values?.instagram_link,
        facebook_link: values?.facebook_link,
        linkedin_link: values?.linkedin_link,
        uploadTimingCertificatePath: uploadedFileData,
        blood_group: values?.blood_group,
        ...consent
      }
    };

    if (custom_fields) {
      for (let i = 0; i < custom_fields?.length; i++) {
        payload.extra[custom_fields[i]?.id] = values[custom_fields[i]?.id];
      }
    }

    if (!eventId) {
      showErrorNotification({ desc: "Cannot find event Id" });
      return;
    }

    if (!event_sub_category_id) {
      showErrorNotification({ desc: "Cannot find event sub category id" });
      return;
    }

    setPaymentsGoingOn(true);

    let result;

    try {
      setPaymentApiStatus(PAYMENT_BACKEND_STATUS.LOADING);
      result = await lambdaInstance.post(
        `/free-order/${eventId}/${event_sub_category_id}`,
        payload
      );
      setPaymentApiStatus(PAYMENT_BACKEND_STATUS.SUCCESS);
    } catch (error) {
      // console.log("payment error", error);
      setPaymentApiStatus(PAYMENT_BACKEND_STATUS.ERROR);
      rollbar.error("Error sending user data to backend", error);
    } finally {
      setPaymentApiStatus(false);
    }

    if (!result) {
      showErrorNotification({
        desc: "Server error. Something went wrong. Please try again later!"
      });
      setPaymentsGoingOn(false);
      return;
    }
    const paymentSuccessdata = result?.data?.payment;
    paymentSuccessDataRef.current = {
      orderId: paymentSuccessdata?.order_id,
      paymentId: paymentSuccessdata?.payment_id,
      email: paymentSuccessdata?.email,
      eventName: paymentSuccessdata?.event_name,
      eventSubCategoryName: paymentSuccessdata?.event_sub_category_name
    };
    setShowPaymentSuccessModal(true);
  };

  const handleSubmit = async (values, consent_config, custom_fields) => {
    // console.log({ values });
    // console.log({ consent_config });

    // checking whether user has uploaded the file or not, if file upload is required.
    const eventSubCategorySelected = values.category;
    if (
      event_sub_category[eventSubCategorySelected]?.extra_info?.uploadTimingCertificate === true
    ) {
      if (!uploadedFileData) {
        showErrorNotification({ desc: "Please upload past timing Certificate to pay" });
        return;
      }
    }

    if (!values?.default_bhaago_india_consent) {
      showErrorNotification({ desc: "Please Check I agree to Bhaggo India Tnc." });
      return;
    }

    const formattedDateOfBirth = dayjs(values.date_of_birth).format("DD-MM-YYYY");

    const event_sub_category_id = event_sub_category[values.category]?.id;

    const consent = {
      default_config: `$text$: I agree to Bhaago India Tnc. $link$: ${TEXT_CONSTANTS.BHAAGOINDIA_TNC_LINK}`
    };

    if (consent_config) {
      for (let i = 0; i < consent_config?.length; i++) {
        if (!values?.[`consent${i + 1}`]) {
          showErrorNotification({ desc: `Please Check ${consent_config[i].text}` });
          return;
        }
        consent[
          `consent ${i + 1}`
        ] = `$text$: ${consent_config[i]?.text}, $link$: ${consent_config[i]?.external_link}`;
      }
    }

    const payload = {
      email: values?.email,
      phone: values?.phone?.toString(),
      name: values?.name,
      tshirt_size: values?.tshirt_size,
      gender: values?.gender,
      address: values?.address,
      //   discount_code: "ABCD",
      emergency_contact_number: values?.emergency_contact_number?.toString(),
      emergency_contact_name: values?.emergency_contact_name,
      extra: {
        whatsapp_contact: values?.whatsapp_contact.toString(),
        city: values?.city,
        date_of_birth: formattedDateOfBirth.toString(),
        instagram_link: values?.instagram_link,
        facebook_link: values?.facebook_link,
        linkedin_link: values?.linkedin_link,
        uploadTimingCertificatePath: uploadedFileData,
        blood_group: values?.blood_group,
        ...consent
      }
    };

    if (values?.coupon_code) {
      payload.coupon_code = values?.coupon_code;
    }

    if (custom_fields) {
      for (let i = 0; i < custom_fields?.length; i++) {
        payload.extra[custom_fields[i]?.id] = values[custom_fields[i]?.id];
      }
    }

    if (!eventId) {
      showErrorNotification({ desc: "Cannot find event Id" });
      return;
    }

    if (!event_sub_category_id) {
      showErrorNotification({ desc: "Cannot find event sub category id" });
      return;
    }

    setPaymentsGoingOn(true);

    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      showErrorNotification({ desc: "Razorpay SDK failed to load. Are you online?" });
      setPaymentsGoingOn(false);
      return;
    }

    let result;

    try {
      setPaymentApiStatus(PAYMENT_BACKEND_STATUS.LOADING);
      result = await lambdaInstance.post(`/order/${eventId}/${event_sub_category_id}`, payload);
      setPaymentApiStatus(PAYMENT_BACKEND_STATUS.SUCCESS);
    } catch (error) {
      // console.log("payment error", error);
      setPaymentApiStatus(PAYMENT_BACKEND_STATUS.ERROR);
      rollbar.error("Error sending user data to backend", error);
    } finally {
      setPaymentApiStatus(false);
    }

    if (!result) {
      showErrorNotification({
        desc: "Server error. Something went wrong. Please try again later!"
      });
      setPaymentsGoingOn(false);
      return;
    }

    const { amount, id: orderId, currency, notes } = result.data.payment;
    const { prefill } = result.data;

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency,
      name: "Bhaago India",
      description: "Payment test for bi",
      order_id: orderId,
      async handler(response) {
        const data = {
          orderCreationId: orderId,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature
        };
        let paymentSuccess;

        try {
          paymentSuccess = await lambdaInstance.post(`/payment/success`, data);
        } catch (error) {
          rollbar.error("Error for api payment success", error);
        } finally {
          setPaymentsGoingOn(false);
        }
        if (!paymentSuccess) {
          showErrorNotification({
            desc: "Server error. Something went wrong. Please try again later!"
          });
          return;
        }
        setShowPaymentSuccessModal(true);
        const paymentSuccessdata = paymentSuccess?.data?.data;
        paymentSuccessDataRef.current = {
          orderId: paymentSuccessdata?.order_id,
          paymentId: paymentSuccessdata?.payment_id,
          email: paymentSuccessdata?.user_details?.email,
          eventName: paymentSuccessdata?.user_details?.event_details?.event_name,
          eventSubCategoryName:
            paymentSuccessdata?.user_details?.event_details?.event_sub_category_name
        };
        setShowPaymentSuccessModal(true);
      },
      prefill: {
        ...prefill
      },
      notes: {
        ...notes
      },
      theme: {
        color: "#61dafb"
      }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleFileUpload = async ({ id, file, fileParams }) => {
    // console.log({ id, file, fileParams });

    const imageResponse = await fetch(fileParams.url);
    const blob = await imageResponse.blob();

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // Upload blob-file to server
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: blob
    };
    setUploadLoader(true);
    try {
      const fileUploadUrl = process.env.REACT_APP_FILE_UPLOAD_WORKER_URL;
      const response = await fetch(
        `${fileUploadUrl}?eventId=${eventId}&type=pastTimingCertificate&fileType=${fileParams.fileType}&fileName=${fileParams.fileName}`,
        requestOptions
      );
      // console.log("response", response);
      const res = await response.json();
      setUploadedFileData(res?.path);
      // console.log("res", res);
    } catch (error) {
      // console.log("file upload error", error);
      showErrorNotification({
        desc: "File upload error!"
      });
      rollbar.error("Error During file upload to R2 using worker", error);
    } finally {
      setUploadLoader(false);
    }
  };

  const handleView = () => {
    setViewFile(true);
  };

  const clearDiscountCouponStates = () => {
    setDiscountCouponError(null);
    setDiscountCouponData(null);
    setIsDiscountLoading(false);
  };

  const handleDiscountCouponApply = async (couponCode, eventSubCategoryId) => {
    if (!couponCode || couponCode?.trim()?.length === 0) {
      return;
    }

    setDiscountCouponError(null);
    setDiscountCouponData(null);

    const payload = {
      coupon_code: couponCode
    };

    let result;

    try {
      setIsDiscountLoading(true);
      result = await lambdaInstance.post(`/validate-coupon/${eventSubCategoryId}`, payload);
    } catch (error) {
      rollbar.error("Error in Coupon Apply Api", error);
      setDiscountCouponError(
        error?.response?.data?.message || "Something went wrong. Please try again later!"
      );
      return;
    } finally {
      setIsDiscountLoading(false);
    }

    if (result) {
      setDiscountCouponData(result?.data);
    }
  };

  return {
    handleSubmit,
    event_sub_category,
    paymentApiStatus,
    showPaymentSuccessModal,
    paymentSuccessData: paymentSuccessDataRef.current,
    closeModal,
    name,
    handleFileUpload,
    uploadLoader,
    uploadedFileData,
    setUploadedFileData,
    viewFile,
    setViewFile,
    handleView,
    handleFreeEventSubmit,
    handleDiscountCouponApply,
    isDiscountLoading,
    discountCouponError,
    discountCouponData,
    clearDiscountCouponStates
  };
};

export default usePaymentsFormSection;

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

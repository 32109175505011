import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider, ErrorBoundary } from "@rollbar/react"; // Provider imports 'rollbar'
import { ConfigProvider } from "antd";
import NoPage from "./pages/Nopage";
// const Payments = lazy(() => import("./pages/Payments"));
import Payments from "./pages/Payments";
import OrderDetails from "./pages/OrderDetails";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true
};

const router = createBrowserRouter([
  // {
  //   path: "/",
  //   element: <Home />
  // },
  {
    path: "/book/:eventId",
    element: <Payments />
  },
  {
    path: "/order-details/:orderId",
    element: <OrderDetails />
  },
  {
    path: "/book-fill/:eventId",
    element: <Payments />
  },
  {
    path: "*",
    element: <NoPage />
  }
]);

function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#de492b"
            }
          }}
        >
          <Suspense fallback={<FallbackUI />}>
            <RouterProvider router={router} />
          </Suspense>
        </ConfigProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;

const FallbackUI = () => {
  return (
    <div className="loader-container">
      <span className="loader"></span>
    </div>
  );
};

import { Form, Input, Switch, Select, InputNumber, DatePicker, Checkbox, Radio, Space } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
// import DebounceSelect from "../common/DebounceSelect";
import { RowShowInputTypes } from "../../utils/constants";

const { Option } = Select;
const { TextArea } = Input;

/** 
 * inputType can be
 * string: Must be of type string. This is the default type.
number: Must be of type number.
boolean: Must be of type boolean.
method: Must be of type function.
regexp: Must be an instance of RegExp or a string that does not generate an exception when creating a new RegExp.
integer: Must be of type number and an integer.
float: Must be of type number and a floating point number.
array: Must be an array as determined by Array.isArray.
object: Must be of type object and not Array.isArray.
enum: Value must exist in the enum.
date: Value must be valid as determined by Date
url: Must be of type url.
hex: Must be of type hex.
email: Must be of type email.
any: Can be any type.
 */

const getProperLabel = (label) => {
  if (typeof label === "string") {
    return label.replace("(Optional)", "");
  }

  if (typeof label?.props?.children?.[0] === "string") {
    return label?.props?.children?.[0].replace("(Optional)", "");
  }

  return "Please put valid input";
};

const EditableForms = ({
  defaultChecked,
  addonBefore,
  label,
  type,
  id,
  arrayData,
  required = true,
  disabled = false,
  pattern,
  // fetchApi,
  labelToShow = false,
  marginBottom = false,
  // mode,
  // debounceId,
  // debounceName,
  // debounceName2,
  disabledDate,
  defaultPickerValue,
  inputType = "string",
  errorLabel
}) => {
  switch (type) {
    case RowShowInputTypes.TEXT:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          rules={[
            {
              required: required,
              type: inputType,
              message: `Please input your ${getProperLabel(label)}!`
            }
          ]}
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <Input disabled={disabled} />
        </Form.Item>
      );
    case RowShowInputTypes.TEXT_AREA:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          rules={[{ required: required, message: `Please input your ${getProperLabel(label)}!` }]}
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <TextArea disabled={disabled} />
        </Form.Item>
      );
    case RowShowInputTypes.NUMBER:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          rules={[{ required: required, message: `Please input valid ${getProperLabel(label)}!` }]}
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <InputNumber disabled={disabled} style={{ width: "100%" }} addonBefore={addonBefore} />
        </Form.Item>
      );
    case RowShowInputTypes.MULTI_SELECT:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          style={marginBottom ? {} : { marginBottom: 0 }}
          rules={[{ required: required, message: `Please input your ${getProperLabel(label)}!` }]}
        >
          <Select mode="multiple" disabled={disabled}>
            {arrayData &&
              arrayData.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      );
    case RowShowInputTypes.TAG:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          valuePropName="checked"
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <Switch disabled={disabled} />
        </Form.Item>
      );

    case RowShowInputTypes.SINGLE_SELECT:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          rules={[
            { required: required, message: `Please select a option from ${getProperLabel(label)}!` }
          ]}
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <Select
            placeholder="Select a option"
            allowClear
            disabled={disabled}
            showSearch
            filterOption={(input, option) =>
              // eslint-disable-next-line no-unsafe-optional-chaining
              (option?.children).toLowerCase().includes(input.toLowerCase())
            }
          >
            {arrayData &&
              arrayData.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      );

    case RowShowInputTypes.DATE:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          style={marginBottom ? {} : { marginBottom: 0 }}
          rules={[{ required: required, message: `Please select a ${getProperLabel(label)}!` }]}
        >
          <DatePicker
            format={"DD-MM-YYYY"}
            disabled={disabled}
            disabledDate={disabledDate}
            defaultPickerValue={defaultPickerValue}
          />
        </Form.Item>
      );
    // case RowShowInputTypes.DEBOUNCE_SELECT:
    //   return (
    //     <Form.Item
    //       label={labelToShow ? label : ""}
    //       name={id}
    //       style={marginBottom ? {} : { marginBottom: 0 }}
    //       rules={[{ required: required, message: `Please select a ${label}!` }]}>
    //       <DebounceSelect
    //         placeholder={`Please search for ${label}`}
    //         fetchOptions={fetchApi}
    //         style={{ width: "100%" }}
    //         disabled={disabled}
    //         mode={mode}
    //         debounceId={debounceId}
    //         debounceName={debounceName}
    //         debounceName2={debounceName2}
    //       />
    //     </Form.Item>
    //   );
    case RowShowInputTypes.PINCODE:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          // rules={[{ required: required, message: `Please input valid ${label}!` }]}
          rules={[
            { required: required, message: `Please input valid ${getProperLabel(label)}!` },
            {
              validator: (rule, typedValue) => {
                if (typedValue.toString().length !== 6) {
                  return Promise.reject("Pincode should of 6 digits");
                }
                return Promise.resolve();
              }
            }
          ]}
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <InputNumber style={{ width: "100%" }} disabled={disabled} />
        </Form.Item>
      );
    case RowShowInputTypes.NUMBER_WITH_CUSTOM_RULE:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          rules={[
            { required: required, message: `Please input valid ${getProperLabel(label)}!` },
            {
              pattern: pattern,
              message: `Please input valid ${label}!`
            }
          ]}
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <InputNumber style={{ width: "100%" }} disabled={disabled} addonBefore={addonBefore} />
        </Form.Item>
      );
    case RowShowInputTypes.STRING_WITH_CUSTOM_RULE:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          rules={[
            { required: required, message: `Please input valid ${getProperLabel(label)}!` },
            {
              pattern: pattern,
              message: `Please input valid ${label}!`
            }
          ]}
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <Input disabled={disabled} />
        </Form.Item>
      );
    case RowShowInputTypes.CHECKBOX:
      return (
        <Form.Item
          valuePropName="checked"
          name={id}
          rules={[
            {
              required: required,
              message: errorLabel ? errorLabel : `Please input your ${getProperLabel(label)}!`
            }
          ]}
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <Checkbox disabled={disabled} defaultChecked={defaultChecked}>
            {labelToShow ? label : ""}
          </Checkbox>
        </Form.Item>
      );
    case RowShowInputTypes.PASSWORD:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          rules={[
            { required: required, message: `Please input your ${getProperLabel(label)}!` },
            {
              pattern: pattern,
              message: `Please enter only 6 valid characters ${getProperLabel(label)}!`
            }
          ]}
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <Input.Password
            placeholder="Input Password"
            iconRender={(visible) =>
              visible ? (
                <EyeTwoTone />
              ) : (
                <EyeInvisibleOutlined style={{ width: "100%" }} disabled={disabled} />
              )
            }
          />
        </Form.Item>
      );
    case RowShowInputTypes.RADIO:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          rules={[{ required: required, message: `Please click ${getProperLabel(label)}` }]}
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <Radio.Group disabled={disabled}>
            <Space direction="vertical">
              {arrayData &&
                arrayData.map((item) => (
                  <Radio
                    value={item.id}
                    key={item.id}
                    disabled={item.buttonDisabled}
                    style={
                      item.buttonDisabled
                        ? {
                            border: "1px solid #f0f0f0",
                            borderRadius: "8px",
                            padding: "8px",
                            width: "100%",
                            backgroundColor: "#ededed"
                          }
                        : {
                            border: "1px solid #f0f0f0",
                            borderRadius: "8px",
                            padding: "8px",
                            width: "100%"
                          }
                    }
                  >
                    {item.buttonDisabled ? item.name : <b>{item.name}</b>}
                  </Radio>
                ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      );

    case RowShowInputTypes.RADIO_BUTTON:
      return (
        <Form.Item
          label={labelToShow ? label : ""}
          name={id}
          rules={[{ required: required, message: `Please click ${getProperLabel(label)}` }]}
          style={marginBottom ? {} : { marginBottom: 0 }}
        >
          <Radio.Group disabled={disabled} buttonStyle="solid">
            {arrayData &&
              arrayData.map((item) => (
                <Radio.Button value={item.id} key={item.id}>
                  {item.name}
                </Radio.Button>
              ))}
          </Radio.Group>
        </Form.Item>
      );

    default:
      return <>NOTE : Invalid Type, Please Check Inside EditableRowShowComponent</>;
  }
};

export default EditableForms;

import { Card, Typography } from "antd";
import { CheckCircleFilled, WhatsAppOutlined } from "@ant-design/icons";
import BasicDivider from "./BasicDivider";
import { TEXT_CONSTANTS } from "../utils/constants";
import { snakeCaseToUIText, trimNumbersToAFixedFigit } from "../utils/helperfunctions";

const { Title, Text } = Typography;

const CategoryCard = ({ eventSubCategoryData, name, discountCouponData }) => {
  const {
    display_name,
    cut_off_time,
    race_start_time,
    // price,
    final_price_data,
    short_text,
    extra_info
  } = eventSubCategoryData || {};

  const {
    // actual_price,
    // description,
    // discount_value,
    // discounted_base_price,
    final_price_data: discountFinalPriceData
  } = discountCouponData || {};

  const finalPriceToUse = discountFinalPriceData || final_price_data;

  return (
    <Card title={display_name} className="m2-b">
      {short_text ? (
        <div dangerouslySetInnerHTML={{ __html: short_text }} className="iframe-container" />
      ) : (
        <></>
      )}

      {cut_off_time ? (
        <Text strong className="flex m0">
          Cutoff Time: <span>{cut_off_time}</span>
        </Text>
      ) : (
        <></>
      )}
      {race_start_time ? (
        <Text strong className="flex m0">
          Race Start Time: <span>{race_start_time}</span>
        </Text>
      ) : (
        <></>
      )}

      {extra_info?.event_type === "FREE" ? (
        <>
          <BasicDivider />
          <Title level={4} className="m0">
            Total: <span className="success">FREE</span>
          </Title>
        </>
      ) : (
        <>
          <Text strong className="flex m0 m1-t m1-b">
            Base Price:{" "}
            <span>{`${TEXT_CONSTANTS?.ruppee_symbol}${finalPriceToUse?.base_price}`}</span>
          </Text>
          {finalPriceToUse?.price_breakup?.map((price_data) => {
            if (price_data?.included === true && getProperUi(price_data)) {
              return (
                <div className="flex m2-l m1-b" key={price_data?.price_type}>
                  <CheckCircleFilled style={{ fontSize: "150%", color: "limegreen" }} />
                  <div className="m1-l">{getProperUi(price_data)}</div>
                </div>
              );
            }
          })}
          {finalPriceToUse?.price_breakup?.map((price_data, index) => {
            if (price_data?.included === false) {
              if (price_data?.price_type === "gst") {
                if (price_data?.gst_number?.trim()?.length !== 15) return <></>;
                // only showing when gst and gst number is on 15 digit
                if (price_data?.applicable) {
                  return (
                    <div key={price_data?.price_type + index}>
                      <Text strong className="flex m0">
                        {/* {snakeCaseToUIText(price_data?.price_type)}:{" "} */}
                        GST:
                        <span>{`${TEXT_CONSTANTS?.ruppee_symbol}${trimNumbersToAFixedFigit(
                          price_data?.charge_inclusion
                        )}`}</span>
                      </Text>
                      <Text className="flex m0" type="secondary">
                        GST Number:{" "}
                        <span>
                          {price_data?.gst_number ?? price_data?.gst_value ?? price_data?.number}
                        </span>
                      </Text>
                    </div>
                  );
                }
                return <></>;
              }
              return (
                <Text strong className="flex m0" key={price_data?.price_type + index}>
                  {snakeCaseToUIText(price_data?.price_type)}:{" "}
                  <span>{`${TEXT_CONSTANTS?.ruppee_symbol}${trimNumbersToAFixedFigit(
                    price_data?.charge_inclusion
                  )}`}</span>
                </Text>
              );
            }
          })}
          <BasicDivider />
          <Title level={4} className="m0">
            Total: <span>{`${TEXT_CONSTANTS?.ruppee_symbol}${finalPriceToUse?.final_price}`}</span>
          </Title>
        </>
      )}

      <p className="m0-b" style={{ fontSize: "12px" }}>
        <span className="fw500">NOTE: </span>
        {TEXT_CONSTANTS.nriText}{" "}
        {
          <a
            href="https://wa.me/919036802196?text=Hey%20Argo%21%20I%20am%20interested%20to%20know%20more%20about%20BhaagoIndia"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppOutlined />
          </a>
        }
      </p>
    </Card>
  );
};

export default CategoryCard;

const getProperUi = (price_data) => {
  if (price_data?.price_type !== "gst") {
    return `${snakeCaseToUIText(price_data?.price_type)}(${price_data?.value}${
      price_data?.type === "percent" ? "%" : ""
    })`;
  }

  if (price_data?.gst_number?.trim()?.length === 15) {
    return `${snakeCaseToUIText(price_data?.price_type)}(${price_data?.value}${
      price_data?.type === "percent" ? "%" : ""
    })(${price_data?.gst_number})`;
  }
  return "";
};

import { Tooltip } from "antd";

const ToolTipComponent = ({ title, children }) => {
  return (
    <Tooltip title={title} color="gold" arrowPointAtCenter placement="topLeft">
      {children}
    </Tooltip>
  );
};

export default ToolTipComponent;

import { Button, Modal, Result } from "antd";
import { redirectToHomePage, redirectToOrderDetails } from "../utils/helperfunctions";
import { TEXT_CONSTANTS } from "../utils/constants";

const PaymentSuccessModal = ({
  isVisible,
  orderId,
  paymentId,
  email,
  handlePaymentSuccessModal,
  eventName,
  eventSubCategoryName
}) => {
  return (
    <>
      <Modal
        title=""
        centered
        open={isVisible}
        onOk={handlePaymentSuccessModal}
        onCancel={handlePaymentSuccessModal}
        footer={[]}
      >
        <Result
          status="success"
          title="Successfully Booked"
          subTitle={
            <>
              <p>
                <span className="fw500 text-black">Event Name: </span>
                {eventName}
              </p>
              <p>
                <span className="fw500 text-black">Event Category: </span>
                {eventSubCategoryName}
              </p>
              <p>
                <span className="fw500 text-black">Order Id: </span>
                {orderId}
              </p>
              <p>
                <span className="fw500 text-black">Payment Id: </span> {paymentId}
              </p>
              <p>
                <span className="fw500 text-black">{email} </span> will get confirmation email in
                some time.
              </p>
              <p className="m0-b" style={{ fontSize: "12px" }}>
                {TEXT_CONSTANTS.helpText.initial}{" "}
                <span className="fw500 text-black">{TEXT_CONSTANTS.helpText.email} </span>
              </p>
            </>
          }
          extra={[
            <Button key="console" onClick={redirectToHomePage} className="m1-t">
              Go To Home Page
            </Button>,
            <Button key="buy" onClick={handlePaymentSuccessModal} className="m1-t">
              Pay Again
            </Button>,
            <Button
              type="primary"
              key="orderConfirmation"
              onClick={() => redirectToOrderDetails(orderId)}
              className="m1-t"
            >
              Order Confimration
            </Button>
          ]}
        />
      </Modal>
    </>
  );
};

export default PaymentSuccessModal;

import { Button } from "antd";

const BIButton = ({ text, handleClick, className, style }) => {
  return (
    <Button
      type="primary"
      onClick={handleClick}
      className={className}
      style={{
        backgroundColor: "var(--color-primary)",
        ...style
      }}
      size="large"
    >
      {text}
    </Button>
  );
};

export default BIButton;

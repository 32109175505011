import BasicLayout from "../components/BasicLayout";
import { useParams } from "react-router-dom";
import useAxios from "../hooks/useAxios";
import NotificationComponent from "../components/NotificationComponent";
import OrderDetailsCard from "../components/Order/OrderDetailsCard";
import { Spin } from "antd";

const OrderDetails = () => {
  const { orderId } = useParams();

  const {
    response: orderData,
    loading,
    error
  } = useAxios({
    method: "get",
    url: `/order-details/${orderId}`
  });

  if (error) {
    return (
      <>
        <NotificationComponent
          type="error"
          message="Error"
          description={error?.message || "Something went wrong"}
        />
      </>
    );
  }

  return (
    <>
      <BasicLayout
        content={
          <section className="flex max-1080">
            {orderData ? (
              <OrderDetailsCard style={{ minWidth: "100%" }} parseOrderData={orderData} />
            ) : (
              <Spin spinning={loading}></Spin>
            )}
          </section>
        }
      />
    </>
  );
};
export default OrderDetails;

// const parseOrderData = ({ orderData, orderId }) => {
//   if (orderData) {
//     const data = orderData;
//     return {
//       status: data?.status,
//       orderId: orderId,
//       payment_id: data?.data.payment_id,
//       phone: data?.data.phone,
//       amount_paid: data?.data.amount_paid,
//       payment_time: data?.data.payment_time,
//       user_details: data?.data.user_details,
//       email: data?.data.email
//     };
//   }

//   return null;
// };

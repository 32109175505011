import { Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
const { Title } = Typography;

const BackButton = ({ text, handleClickBackButton, className, style }) => {
  return (
    <section className={`flex align-center ${className}`} style={style}>
      <ArrowLeftOutlined onClick={handleClickBackButton} style={{ fontSize: "150%" }} />
      <Title level={4} className="m0 m1-l m1-t m1-b">
        {text}
      </Title>
    </section>
  );
};

export default BackButton;

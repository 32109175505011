import { Card, Spin, Button } from "antd";
import { getFormattedDate } from "../utils/helperfunctions";
import BibInfoModel from "./BibInfoModel";

const parseBibCollectionInfoData = ({ parsedEventData }) => {
  if (parsedEventData) {
    const data = parsedEventData;
    return {
      eventName: data.name,
      eventDate: data.event_formatted_date,
      startTime: data.event_start_time,
      eventCityState: data.event_city_state,
      bibCollectionData: data.event_info.bib_collection_data,
      bibCollectionDetails: data.event_info.bib_collection_data.bib_collection_details
    };
  }

  return null;
};

const PaymentsCard = ({ style, loading, parsedEventData }) => {
  const { name, html_details, location, event_date, last_registration_date, event_info } =
    parsedEventData || {};

  return (
    <div style={style} className="minHeight100 bg-white">
      <Spin spinning={loading}>
        <Card
          title={
            event_info?.bib_collection_data ? (
              <div
                style={{ justifyContent: "space-between", display: "flex", alignContent: "center" }}
              >
                <b>{name}</b>
                <Button
                  // className={styles.bibButtonStyle}
                  type="primary"
                  onClick={() => {
                    BibInfoModel(parseBibCollectionInfoData({ parsedEventData }));
                  }}
                >
                  BiB Info
                </Button>
              </div>
            ) : (
              <b>{name}</b>
            )
          }
          className="p3-b"
        >
          {location ? (
            <>
              <div className="flex">
                <p className="m0 fw500" style={{ minWidth: "10rem" }}>
                  Location:{" "}
                </p>
                <div className="m1-l">
                  {getCityWithStateName({ city: location?.city, state: location?.state })}
                </div>
              </div>
              {/* <div>{location?.description}</div> */}
            </>
          ) : (
            <></>
          )}
          {event_date || last_registration_date ? (
            <div className="flex">
              <p className="m0 fw500" style={{ minWidth: "10rem" }}>
                Event Date:{" "}
              </p>
              <div className="m1-l">{getFormattedDate(event_date || last_registration_date)}</div>
            </div>
          ) : (
            <></>
          )}
          {last_registration_date ? (
            <div className="flex">
              <p className="m0 fw500" style={{ minWidth: "10rem" }}>
                Last Registration Date:{" "}
              </p>
              <div className="m1-l">{getFormattedDate(last_registration_date)}</div>
            </div>
          ) : (
            <></>
          )}
          <div dangerouslySetInnerHTML={{ __html: html_details }} className="iframe-container" />
        </Card>
      </Spin>
    </div>
  );
};

export default PaymentsCard;

const getCityWithStateName = ({ city, state }) => {
  let word = "";

  if (city) {
    word += city;
  }

  if (state) {
    if (word?.length > 0) {
      word += ", ";
    }

    word += state;
  }

  return word;
};
